<template>
  <div v-loading="pageloading">
    <el-card v-loading = "toploading">
      <div class = "flexRow flexcontent">
        <div class = "titleFont">{{information.ActivityName}}</div>
        <div v-if="getstate()==0" class = "successstyle nosuccesss">活动未开始</div>
        <div v-else-if="getstate()==1" class = "successstyle">活动进行中</div>
        <div  v-else-if="getstate()==2" class = "successstyle nosuccesss">活动已结束</div>
      </div>
      <div class = "minorFont" style="margin-top:15px">活动时间：
        <span style="margin-left:15px">{{information.StartTime}} <span style="margin:0px 10px">至</span> {{information.EndTime}}</span>
      </div>
      <div class = "minorFont">推广群聊：
        <span style="margin-left:15px">{{information.JoinGroupCount}}个</span>
        <el-button type="text" style="margin-left:20px"
         @click="$router.push({path:'/Customer/groupfission/groupfissionEdit?Id='+$route.query.Id})">查看详情</el-button>
      </div>
    </el-card>
    <el-card v-loading = "toploading" style="margin-top:10px">
      <div class = "titleFont">活动数据</div>
      <div class = "flexRow" style="margin-top:20px">
        <div class = "datebox">
          <div class = "flexRow flexcontent orsocenter" style="height:80px;font-size:18px">
            {{information.JoinMemberCount?information.JoinMemberCount:0}}</div>
          <div class = "flexRow flexcontent orsocenter" style="font-size:14px">
            参与活动客户
            <el-tooltip class="item" effect="dark" popper-class="tooltipwidth"
             content="参与本次裂变活动的客户数量" placement="top">
							<i class="el-icon-warning-outline" style="font-size:16px;margin:0px 0px 0px 5px"></i>
						</el-tooltip>
          </div>
        </div>
        <div class = "datebox">
          <div class = "flexRow flexcontent orsocenter msgbox">
            {{information.FinishTaskMemberCount?information.FinishTaskMemberCount:0}}</div>
          <div class = "flexRow flexcontent orsocenter" style="font-size:14px">
            完成任务客户
            <el-tooltip class="item" effect="dark" popper-class="tooltipwidth"
             content="成功完成第一阶梯的客户数量，人数去重，1个客户完成多个阶梯，完成任务客户数量计1个" placement="top">
							<i class="el-icon-warning-outline" style="font-size:16px;margin:0px 0px 0px 5px"></i>
						</el-tooltip>
          </div>
        </div>
        <div class = "datebox">
          <div class = "flexRow flexcontent orsocenter msgbox">
            {{information.ReceiveRewardsMemberCount?information.ReceiveRewardsMemberCount:0}}</div>
          <div class = "flexRow flexcontent orsocenter" style="font-size:14px">
            领取奖品客户
            <el-tooltip class="item" effect="dark" popper-class="tooltipwidth"
             content="成功领取任一阶梯奖品的客户数量，人数去重，1个客户领取多个阶梯奖品，领奖客户数量计1个" placement="top">
							<i class="el-icon-warning-outline" style="font-size:16px;margin:0px 0px 0px 5px"></i>
						</el-tooltip>
          </div>
        </div>
        <div class = "datebox">
          <div class = "flexRow flexcontent orsocenter msgbox">
            {{information.JoinGroupMemberCount?information.JoinGroupMemberCount:0}}</div>
          <div class = "flexRow flexcontent orsocenter" style="font-size:14px">
            活动入群客户数
            <el-tooltip class="item" effect="dark" popper-class="tooltipwidth"
             content="通过本次裂变活动，成功加入活动群聊的人数，1个客户添加多个活动群聊，入群客户数计多个" placement="top">
							<i class="el-icon-warning-outline" style="font-size:16px;margin:0px 0px 0px 5px"></i>
						</el-tooltip>
          </div>
        </div>
      </div>
      <div style="margin-top:20px">
        <el-table :data="tablelist" style="width:80%">
          <el-table-column prop="LevelDesc" label="任务阶梯"></el-table-column>
          <el-table-column prop="TargetMemberCount" label="目标人数"></el-table-column>
          <el-table-column prop="HaveFinishTaskMemberCount" label="已完成任务客户数"></el-table-column>
          <el-table-column prop="ReceiveRewardsMemberCount" label="已领取奖品客户数"></el-table-column>
        </el-table>
      </div>
    </el-card>
    <el-card style="margin:10px 0px 80px 0px">
      <el-tabs v-model="activeName" type="card"  @tab-click="handleClick">
        <el-tab-pane label="推广群聊数据" name="1"></el-tab-pane>
        <el-tab-pane label="活动客户数据" name="2"></el-tab-pane>
      </el-tabs>
      <div v-if="activeName==1">
        <div class = "flexRow" style="margin-top:20px">
          <div class = "flexRow" style="margin:0px 15px">
            <div class = "queryFont">关键字：</div>
            <el-input v-model="keywords" placeholder="群聊名称" style="width:200px"></el-input>
          </div>
          <el-button type="primary" @click="query">查询</el-button>
        </div>
        <div style="margin-top:10px;text-align:right">
          <el-table :data="grouplist" v-loading="grouploading" key="grouplist">
            <el-table-column prop="" label="群名称" width="300px">
              <template slot-scope="scope">
                <div class = "flexRow">
                  <img src="@/assets/img/50px.png" style="width:40px;height:40px;border-radius:3px" alt="">
                  <div  style="width:100%;margin-left:5px;margin-top:-3px">
                    <div style="width:250px" class = "fontHidden2">{{scope.row.GroupName}}</div>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="" label="群主">
              <template slot-scope="scope">
                <el-tag>
									<i class="el-icon-user"></i>
									{{scope.row.EmployeeName}}
								</el-tag>
              </template>
            </el-table-column>
            <el-table-column prop="CreateTime" label="建群时间"></el-table-column>
            <el-table-column prop="GroupDetailCount" label="群内人数">
              <template slot="header" slot-scope="scope">
                  <div class="flexRow">
                    <div>群内人数</div>
                    <el-tooltip class="item" effect="dark" content="群内所有成员人数，包括企业微信员工和微信客户" placement="top">
                      <i class="el-icon-warning-outline"
                        style="font-size:18px;margin:2px 0px 0px 5px"></i>
                    </el-tooltip>
                  </div>
                </template>
            </el-table-column>
            <el-table-column prop="MemberCount" label="群内客户数">
              <template slot="header" slot-scope="scope">
                  <div class="flexRow">
                    <div>群内客户数</div>
                    <el-tooltip class="item" effect="dark" content="群内所有微信客户数" placement="top">
                      <i class="el-icon-warning-outline"
                        style="font-size:18px;margin:2px 0px 0px 5px"></i>
                    </el-tooltip>
                  </div>
                </template>
            </el-table-column>
            <el-table-column prop="ActivityJoinMemberCount" label="活动入群客户数">
              <template slot="header" slot-scope="scope">
                  <div class="flexRow">
                    <div>活动入群客户数</div>
                    <el-tooltip class="item" effect="dark" content="通过本次裂变活动，成功加入群聊的微信客户数量" placement="top">
                      <i class="el-icon-warning-outline"
                        style="font-size:18px;margin:2px 0px 0px 5px"></i>
                    </el-tooltip>
                  </div>
                </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div v-if="activeName==2">
        <div class = "flexRow" style="margin-top:20px">
          <div class = "flexRow">
            <div class = "queryFont">关键字：</div>
            <el-input v-model="keywords" placeholder="客户微信昵称、手机号" style="width:200px"></el-input>
          </div>
          <div class = "flexRow" style="margin:0px 15px">
            <div class = "queryFont">任务阶梯 ：</div>
            <el-select v-model="ladder"  placeholder="请选择任务阶梯" style="width:200px" clearable >
              <el-option v-for="(v,i) in ladderlist" :key="i" :value="v.Level" :label="v.Desc"></el-option>
            </el-select>
          </div>
          <el-button type="primary" @click="query">查询</el-button>
        </div>
        <div style="margin-top:10px">
          <el-table :data="datalist" v-loading="dataloading" key="datalist">
            <el-table-column prop="" label="活动客户" width="200px">
              <template slot="header" slot-scope="scope">
                  <div class="flexRow">
                    <div>活动客户</div>
                    <el-tooltip class="item" effect="dark" content="参加本次裂变活动的客户" placement="top">
                      <i class="el-icon-warning-outline"
                        style="font-size:18px;margin:2px 0px 0px 5px"></i>
                    </el-tooltip>
                  </div>
              </template>
              <template slot-scope="scope">
                <div class = "flexRow">
                  <img :src="scope.row.WxHeadUrl?scope.row.WxHeadUrl:defaultImg" style="width:40px;height:40px;border-radius:3px" alt="">
                  <div style="width:100%;margin-left:5px;margin-top:-3px">
                    <div style="width:150px" class = "fontHidden">{{scope.row.WxNickname}}</div>
                    <div style="width:150px" class = "fontHidden">{{scope.row.Phone}}</div>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="AddTime" label="参与活动时间"></el-table-column>
            <el-table-column prop="" label="邀请客户" width="200px">
              <template slot="header" slot-scope="scope">
                  <div class="flexRow">
                    <div>邀请客户</div>
                    <el-tooltip class="item" effect="dark" content="第1个邀请客户参加该活动的人" placement="top">
                      <i class="el-icon-warning-outline"
                        style="font-size:18px;margin:2px 0px 0px 5px"></i>
                    </el-tooltip>
                  </div>
                </template>
                <template slot-scope="scope">
                <div class = "flexRow" v-if="scope.row.InviteWxNickname">
                  <img :src="scope.row.InviteWxHeadUrl?scope.row.InviteWxHeadUrl:defaultImg" style="width:40px;height:40px;border-radius:3px" alt="">
                  <div style="width:100%;margin-left:5px;margin-top:-3px">
                    <div style="width:150px" class = "fontHidden">{{scope.row.InviteWxNickname}}</div>
                    <div style="width:150px" class = "fontHidden">{{scope.row.InvitePhone}}</div>
                  </div>
                </div>
                <div v-else>
                  — —
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="LevelDesc" label="当前完成阶梯">
               <template slot-scope="scope">
                <div v-if="scope.row.LevelDesc">{{scope.row.LevelDesc}}</div>
                <div v-else>— —</div>
              </template>
            </el-table-column>
            <el-table-column prop="AllHelpCount" label="裂变人数">
              <template slot="header" slot-scope="scope">
                  <div class="flexRow">
                    <div>裂变人数</div>
                    <el-tooltip class="item" effect="dark" content="本次裂变活动，通过该客户专属海报成功加入推广群聊的人数" placement="top">
                      <i class="el-icon-warning-outline"
                        style="font-size:18px;margin:2px 0px 0px 5px"></i>
                    </el-tooltip>
                  </div>
                </template>
            </el-table-column>
            <el-table-column prop="HelpCount" label="有效人数">
              <template slot="header" slot-scope="scope">
                  <div class="flexRow">
                    <div>有效人数</div>
                    <el-tooltip class="item" effect="dark" content="裂变人数当中，未退出所有推广群聊的人数" placement="top">
                      <i class="el-icon-warning-outline"
                        style="font-size:18px;margin:2px 0px 0px 5px"></i>
                    </el-tooltip>
                  </div>
                </template>
            </el-table-column>
            <el-table-column prop="" label="操作">
              <template slot-scope="scope">
                <el-button type="text" @click="record(scope.row)">裂变记录</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div style="margin-top:10px;text-align:right">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="currentPage"
          :page-sizes="[10, 20, 30, 40]"
          :page-size.sync="sizepage"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
        </el-pagination>
      </div>
    </el-card>
    <el-card class = "fixed" style="text-align:center">
      <el-button @click="$router.go(-1)">返回活动列表</el-button>
    </el-card>

    <el-dialog title="裂变记录" :visible.sync="recordshow" v-loading = "recordloading" width="70%" @close = "recordclose">
      <div class = "flexRow" style="flex-wrap:wrap">
        <div class = "flexRow">
          <div class = "queryFont">关键字：</div>
          <el-input v-model="keywords2" style="width:200px" placeholder="客户微信昵称、手机号"></el-input>
        </div>
        <div class = "flexRow" style="margin:0px 15px">
          <div class = "queryFont">裂变状态：</div>
          <el-select v-model="fission" clearable placeholder="请选择裂变状态" style="width:200px">
            <el-option v-for="(v,i) in fissionlist" :key="i" :value="v.value" :label="v.label"></el-option>
          </el-select>
        </div>
        <el-button type="primary" @click="query2">查询</el-button>
      </div>
      <div class = "flexRow" style="margin:20px 0px">
        <div>当前完成阶梯：{{personnel.LevelDesc}}</div>
        <div style="margin:0px 30px">裂变人数：{{personnel.AllHelpCount}} </div>
        <div>有效人数：{{personnel.HelpCount}} </div>
      </div>
      <div>
        <el-table :data="fissiontablelist" v-loading = "fissiontableloading" max-height="500px">
          <el-table-column prop="" label="客户" width="300px">
            <template slot-scope="scope">
              <div class = "flexRow">
                <img :src="scope.row.WxHeadUrl?scope.row.WxHeadUrl:defaultImg" style="width:40px;height:40px;border-radius:3px" alt="">
                <div style="width:100%;margin-left:5px;margin-top:-3px">
                  <div style="width:250px" class = "fontHidden">{{scope.row.WxNickname}}</div>
                  <div style="width:250px" class = "fontHidden">{{scope.row.Phone}}</div>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="AddTime" label="裂变时间"></el-table-column>
          <el-table-column prop="State" label="裂变状态"></el-table-column>
          <el-table-column prop="InvalidTime" label="失效时间">
            <template slot-scope="scope">
              <div>{{scope.row.IsInvalid?scope.row.InvalidTime:'— —'}}</div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div style="margin-top:10px;text-align:right">
        <el-pagination
          @size-change="handleSizeChange2"
          @current-change="handleCurrentChange2"
          :current-page="currentPage2"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="sizepage2"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total2">
        </el-pagination>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import config from "@/config"
import {
  qyweixinqyweixingroupfissionrecordstatistics,
  qyweixinqyweixingroupfissionactivitymemberdata,
  qyweixinqyweixingroupfissionrecord,
  qyweixinqyweixingroupfissionactivitytask,
  qyweixinqyweixingroupfissiongroupstatistics
} from "@/api/sv1.0.0"
export default {
  data () {
    return {
      defaultImg: config.DEFAULT_HEADER,
      activeName:'1',
      imgurl:config.IMG_BASE,
      pageloading:false,
      information:{},
      toploading:false,
      tablelist:[],
      keywords:'',
      ladder:null,
      ladderlist:[],
      grouplist:[],
      grouploading:false,
      datalist:[],
      dataloading:false,
      personnel:{},
      currentPage:1,
      sizepage:20,
      total:null,
      recordshow:false,
      recordloading:false,
      keywords2:'',
      fission:null,
      fissionlist:[
        {value:false,label:'裂变成功'},
        {value:true,label:'已失效'},
      ],
      fissiontablelist:[],
      currentPage2:1,
      sizepage2:10,
      total2:null,
      fissiontableloading:false,
    }
  },
  created () {
    this.getqyweixinqyweixingroupfissionrecordstatistics()
    this.getgrouplist()
  },
  methods: {
    getstate(){
      if(this.information.IsFinish){
        return 2
      }else{
          let date = new Date().getTime()
          let starttime = new Date(this.information.StartTime).getTime()
          let endtime = new Date(this.information.EndTime).getTime()
          // console.log(starttime>date)
          if(starttime>date){
            return 0
          }else if(starttime<=date && endtime>date){
            return 1
          }else if(endtime<date){
            return 2
          }
      }
        
    },
    //切换tabs
    handleClick(tab){
      this.keywords = ''
      this.ladder = null
      this.currentPage=1
      this.sizepage=20
      this.total=null
      this.$forceUpdate()
      if(tab.name==1){
         this.getgrouplist()
        // console.log(tab.name)
      }else if(tab.name==2){
        // console.log(tab.name)
        this.getdatalist()
        this.getqyweixinqyweixingroupfissionactivitytask()
      }
      console.log(this.currentPage)
    },
    recordclose(){
      this.keywords2=''
      this.fission=null
      this.currentPage2 = 1
      this.sizepage2 = 10
    },
    query2(){
      this.currentPage2=1
      this.getfissiontablelist()
    },
    query(){
      this.currentPage=1
      if(this.activeName==1){
        this.getgrouplist()
      }else if(this.activeName==2){
        this.getdatalist()
      }
    },
    handleSizeChange2(e){
      this.currentPage2 = 1
      this.sizepage2 = e
      this.getfissiontablelist()
    },
    handleCurrentChange2(e){
      this.currentPage2 = e
      this.getfissiontablelist()
    },
    //裂变记录
    async getfissiontablelist(){
      this.fissiontableloading = true
      try{
        let data = {
          Id:this.$route.query.Id,
          MemberId:this.personnel.MemberId,
          Keywords:this.keywords2,
          IsInvalid:this.fission,
          Skip:(this.currentPage2-1)*this.sizepage2,
          Take:this.sizepage2
        }
        let result = await qyweixinqyweixingroupfissionrecord(data)
        if(result.IsSuccess){
          this.fissiontablelist = result.Result.Results
          this.total2 = result.Result.Total
        }
      }finally{
        this.fissiontableloading = false
      }
    },
    record(e){
      this.personnel = e
      this.recordshow = true
      this.getfissiontablelist()
    },
    handleSizeChange(e){
      this.currentPage = 1
      this.sizepage = e
      if(this.activeName==1){
        this.getgrouplist()
      }else if(this.activeName==2){
        this.getdatalist()
        this.getqyweixinqyweixingroupfissionactivitytask()
        // console.log(this.activeName)
      }
      // this.getdatalist()
    },
    handleCurrentChange(e){
      this.currentPage = e
      if(this.activeName==1){
        this.getgrouplist()
      }else if(this.activeName==2){
        this.getdatalist()
        this.getqyweixinqyweixingroupfissionactivitytask()
        // console.log(this.activeName)
      }
      // this.getdatalist()
    },
    //活动客户数据
    async getdatalist(){
      this.dataloading = true
      try{
        let data = {
          Id:this.$route.query.Id,
          Keywords:this.keywords,
          TaskLevel:this.ladder,
          Skip:(this.currentPage-1)*this.sizepage,
          Take:this.sizepage
        }
        let result = await qyweixinqyweixingroupfissionactivitymemberdata(data)
        if(result.IsSuccess){
          this.datalist = result.Result.Results
          this.total = result.Result.Total
        }
      }finally{
        this.dataloading = false
      }
    },
    //推广群聊数据
    async getgrouplist(){
      this.grouploading = true
      try{
        let data = {
          Id:this.$route.query.Id,
          Keywords:this.keywords,
          Skip:(this.currentPage-1)*this.sizepage,
          Take:this.sizepage
        }
        let result = await qyweixinqyweixingroupfissiongroupstatistics(data)
        if(result.IsSuccess){
          // console.log(result)
          this.grouplist = result.Result.Results
          this.total = result.Result.Total
        }
      }finally{
        this.grouploading = false
      }
    },
    //顶部数据
    async getqyweixinqyweixingroupfissionrecordstatistics(){
      this.toploading = true
      try{
        let result = await qyweixinqyweixingroupfissionrecordstatistics({Id:this.$route.query.Id})
        if(result.IsSuccess){
          this.information = result.Result
          this.tablelist = result.Result.TaskStatiticsList
          // this.getladder(this.tablelist)
        }
      }finally{
        this.toploading = false
      } 
    },
    getladder(list){
      let arr = ['一','二','三','四','五','六','七',]
      this.ladderlist = list.map((v,i)=>{
        let data = {
          value:i,
          label:arr[i]+'级阶梯'
        }
        return data
      })
    },
    //阶梯
    async getqyweixinqyweixingroupfissionactivitytask(){
      let data = {
        Id:this.$route.query.Id
      }
      let result = await qyweixinqyweixingroupfissionactivitytask(data)
      if(result.IsSuccess){
        // console.log(result.Result)
        this.ladderlist = result.Result
      }
    },
  },
}
</script>

<style lang = "less" scoped>
  .flexRow{
    display: flex;
    flex-direction: row;
  }
  .flexcontent{
    align-items: center;
  }
  .orsocenter{
    justify-content: center;
  }
  .titleFont{
    font-size:18px;
    color: black;
    font-weight:bold;
  }
  .successstyle{
    color: #67C23A;
    background: #f0f9eb;
    padding: 7px 10px;
    margin-left: 15px;
    font-size: 12px;
    text-align: center;
    border: 1px solid #c2e7b0;
    border-radius: 15px;
  }
  .nosuccesss{
    color: #909399;
    background: #f4f4f5;
    border-color: #d3d4d6;
  }
  .minorFont{
    color: #606266;
    font-size: 14px;
    line-height: 1;
  }
  .msgbox{
    height:80px;
    font-size: 18px;
  }
  .queryFont{
    font-size: 14px;
    /* width: 80px; */
    line-height: 36px;
    font-weight: bold;
    color: #606266;
    word-break: normal;
  }
  .fontHidden{
    overflow: hidden;
    text-overflow:ellipsis;
    white-space: nowrap;
  }
  .fontHidden2{
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }
  .fixed{
    position: fixed;
    left: 200px;
    right: 10px;
    bottom: 0px;
    height: 80px;
  }
  .datebox{
    width:180px;
    height: 100px;
  }
  .datebox:first-child{
    width:110px;
  }
</style>
<style>
  .tooltipwidth {
    max-width: 300px !important;
  }
</style>